<template>
  <div style="font-family: Raleway, sans-serif">
    <section class="bannerImg" id="contactus">
      <HeaderStats />
      <b-row class="w-100 cont_ani">
        <b-col cols="12" sm="6" class="ml-lg-5 pt-lg-0 bannermr">
          <h1 class="text-white text-banner" style="font-weight: 700">
            Expand Your Business With <br />Verified International Buyers Data
          </h1>
          <p class="text-white pt-lg-4">
            Genuine Importers-Exporters Data of all Countries at Your
            Fingertips
          </p>
          <b-row>
            <div cols="2" sm="6" class="w-100 ml-lg-3">
              <span v-if="!isLoggedIn" class="headers" style="
                  text-decoration: none !important;
                  color: white !important;
                " v-b-modal.modal-register>
                <p class="text-white btn btn-three mr-2" style="padding: 0.5rem 2rem">
                  Get Started
                </p>
              </span>
              <a href="/#inquiryform" style="
                  text-decoration: none !important;
                  color: black !important;
                ">
                <p class="text-white btn btn-three11" style="padding: 0.5rem 2rem">
                  Contact Us
                </p>
              </a>
              <div id="aboutus"></div>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section class="w-100 p-lg-3 pl-4 bg-img">
      <b-row class="text-center pt-4 w-100">
        <h2 class="section-subtitle section3-title text-blue h2-color" style="font-weight: 700">
          <b>About Us</b>
        </h2>
        <hr style="width: 18%; margin-left: 40%; margin-top: 5px" />
      </b-row>
      <b-row class="w-100 text-center w-100">
        <b-col cols="12" sm="2"> </b-col>
        <b-col cols="12" sm="8">
          <p class="hover-shadow pt-2 font-1">
            Expodatabox provides up-to-date information about overseas importers
            and exporters for the companies aiming to make trade connections in
            international ceramic markets and driving your business to new
            heights with Export-Import intelligence database and powerful data
            analysis research that provides a deeper insight into international
            markets and the competitors.
          </p>
        </b-col>
      </b-row>
      <b-row class="ml-lg-2 w-100 mt-3 justify-content-center pb-5 gap-5" style="padding-bottom:2.5rem !important;">
        <b-col cols="12" sm="3" class="backblue background w-lg-25 text-center rounded-lg">
          <b-row class="pb-3">
            <b-icon icon="card-list" font-scale="5" class="text-white mt-4 mb-3"></b-icon>
            <p class="mb-1 text-white hover-zoom">
              Expand and Reach New Markets
            </p>
          </b-row>
        </b-col>
        <b-col cols="12" sm="3" class="backblue background w-lg-25 text-center rounded-lg">
          <b-row class="">
            <b-icon icon="layers" font-scale="5" class="text-white mt-4 mb-3"></b-icon>
            <p class="mb-1 text-white">Generate more Sales</p>
          </b-row>
        </b-col>

        <b-col cols="12" sm="3" class="backblue background w-lg-25 text-center rounded-lg">
          <b-row class="">
            <b-icon icon="graph-up" font-scale="5" class="text-white mt-4 mb-3"></b-icon>
            <p class="mb-1 text-white">Grow your Business</p>
          </b-row>
        </b-col>
      </b-row>
    </section>

    <section class="w-100 find-agency">
      <div class="pb-5 pr-6 mr-15 pl-3 bgimage2">
        <div class="text-center p-5 mr-15 mb-0 pb-0">
          <h2 class="section-subtitle section3-title text-white">
            <b>Our Features</b>
          </h2>
        </div>
        <b-row class="text-center white-color w-100 pt-3">
          <b-col cols="12" sm="3"> </b-col>
          <b-col cols="12" sm="6">
            <p class="font-1">
              Expand your business with the great features provided to our
              clients. Here’s how we help your business grow with our services
            </p>
          </b-col>
        </b-row>
        <b-row class="w-100 p-5 pt-0 pb-0">
          <b-col cols="12" sm="3" class="
              backshadow
              pd-60
              h-100
              text-center
              rounded
              text-white
              shad_cust
            ">
            <b-card-text class="agency-subtile">
              <p>
                <b-icon class="mb-lg-4 mt-lg-4 mt-2 mb-3" scale="4.5" icon="person"></b-icon><br />
              </p>
              <p class="letter-spacing-0 font-size-18 mb-3">
                <strong>International Buyer's</strong>
              </p>
            </b-card-text>
          </b-col>
          <b-col cols="12" sm="3" class="
              backshadow
              pd-60
              h-100
              text-center
              border-radius-15 border-2-color
              text-white
            ">
            <b-card-text class="agency-subtile">
              <p>
                <b-icon class="mb-lg-4 mt-lg-4 mt-2 mb-3" scale="4.5" icon="tag"></b-icon><br />
              </p>
              <p class="letter-spacing-0 font-size-18">
                <strong>Genuine Data</strong>
              </p>
            </b-card-text>
          </b-col>

          <b-col cols="12" sm="3" class="
              backshadow
              pd-60
              h-100
              text-center
              border-radius-15 border-2-color
              text-white
            ">
            <b-card-text class="agency-subtile">
              <p>
                <b-icon class="mb-lg-4 mt-lg-4 mt-2 mb-3" scale="4.5" icon="credit-card2-back"></b-icon><br />
              </p>
              <p class="letter-spacing-0 font-size-18 mb-3">
                <strong>Flexible Pricing</strong>
              </p>
            </b-card-text>
          </b-col>
          <b-col cols="12" sm="3" class="
              backshadow
              pd-60
              h-100
              text-center
              border-radius-15 border-2-color
              text-white
            ">
            <b-card-text class="agency-subtile">
              <p>
                <b-icon class="mb-lg-4 mt-lg-4 mt-2 mb-3" scale="4.5" icon="folder"></b-icon><br />
              </p>
              <p class="letter-spacing-0 font-size-18">
                <strong>Regular Data Updates</strong>
              </p>
            </b-card-text>
            <div id="plans"></div>
          </b-col>
        </b-row>
      </div>
    </section>
    <section>
      <Plans />
    </section>

    <section class="w-100 find-agency mb-4">

      <div class="text-center pt-4 mr-15 mb-0 pb-0 ">
        <h2 class="section-subtitle section3-title pink">
          <b>Testimonial</b>
        </h2>
      </div>
      <hr style="width: 18%; margin-left: 40%; margin-top: 5px" />
      <b-row class="w-100 text-center w-100">
        <b-col cols="12" sm="2"> </b-col>
        <b-col cols="12" sm="8">
          <p class="hover-shadow pt-2 pl-4 font-1">
            <b>What Our Clients Says?</b>
          </p>
        </b-col>
      </b-row>
      <template>
        <div class="row  g-0 ml-lg-5 mr-lg-5">
          <VueSlickCarousel class="  ml-lg-0 " v-bind="settingco">

            <b-col sm="11" class="item pr-lg-0 ">

              <div class="shadow-effect">
                <div class="pb-4 pt-1"><img class="img-circle" src="/img/testi.png" width="9%" alt=""></div>
                <p class="p_fontfamilytestimonial"> We expand our tiles export business reach from 10 countries to 30
                  countries using expodatabox verified database.</p>
                <p class="pt-4"><b>President Ceramic</b><br> <span>CEO,Founder</span></p>
              </div>
            </b-col>
            <!-- <b-col  cols="12" sm="1" class="item "></b-col> -->
            <b-col sm="11" class="item pr-lg-0">
              <div class="shadow-effect" style="height: 15rem;">
                <div class="pb-4"><img class="img-circle" src="/img/testi.png" width="9%" alt=""></div>
                <p class="p_fontfamilytestimonial">After using a FREE trial, we are completely satisfied with their data
                  and bought a paid plan from them. It really helps to reach out to new customers within a short period
                  of time.
                </p>
                <p class="pt-1"><b>Shah & co.</b><br> <span>Co-Founder</span></p>
              </div>
            </b-col>


            <b-col sm="11" class="item pr-lg-0">
              <div class="shadow-effect">
                <div class="pb-4"><img class="img-circle" src="/img/testi.png" width="9%" alt=""></div>
                <p class="p_fontfamilytestimonial extra"> As an import-exporter, the need for genuine buyer's data is
                  very important. Expodatabox solves <br> this problem within one click.</p>
                <p class="pt-4"><b>Maruti overseas</b><br> <span>MD</span></p>
              </div>

            </b-col>


            <b-col sm="11" class="item pr-lg-0 ">
              <div class="shadow-effect" style="height: 15rem;">
                <div class="pb-4"><img class="img-circle" src="/img/testi.png" width="9%" alt=""></div>
                <p class="p_fontfamilytestimonial pt-0"> As a ceramic and porcelain tiles exporter, I waste lots of
                  money on buying useless buyers' data which did not add any value to my business. After buying a Gold
                  plan from Expodatabox, I found it verified and got a high ROI.</p>
                <p class="p-1"><b>Star Export House</b><br> <span>Manager</span></p>
              </div>
            </b-col>
            <div id="inquiryform"></div>
          </VueSlickCarousel>

        </div>

      </template>
    </section>
    <section class="bg-contact">
      <b-row class="ml-3 mr-3 ml-lg-0 lr-lg-0">
        <b-col cols="12" sm="1"></b-col>
        <b-col cols="12" sm="6" class="">
          <h2 class="fw-bolder font-color pt-4 pb-4 mt-3">Inquiry</h2>
          <CForm class="text-center" @submit.prevent="sendEnquiry">
            <CRow>
              <CCol class="" cols="12" sm="5">
                <CInput type="text" placeholder="First Name" onkeypress="return /[a-z]/i.test(event.key)"
                  v-model="inquiry.fname" required />
              </CCol>
              <CCol cols="12" sm="5">
                <CInput type="text" placeholder="Last Name" onkeypress="return /[a-z]/i.test(event.key)"
                  v-model="inquiry.lname" />
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" sm="10">
                <CInput type="email" placeholder="Email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                  v-model="inquiry.email" required />
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" sm="10">
                <b-form-input class="mb-3" type="number" v-model="inquiry.mobile" id="mobile"
                  placeholder="Mobile Number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="14" minlength="8" required></b-form-input>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="10">
                <b-form-textarea id="textarea" placeholder="Enter something..." rows="3" max-rows="6"
                  v-model="inquiry.message" required></b-form-textarea>
              </CCol>
            </CRow>
            <CRow class="">
              <CCol sm="10" class="mt-3 mb-4">
                <button class="text-white btn-three35" style="border: none" :disabled="disabled == 1">
                  Send Message
                </button>
                <span v-if="loading == true">
                  <LoadingBar />
                </span>
                <span class="text-primary">{{ messagemail }}</span>
              </CCol>
            </CRow>
          </CForm>
        </b-col>

      </b-row>
    </section>

    <div>
    </div>
    <b-modal label="" size="lg" class="modal fade backgroundimg " ref="my-modal" id="modal-subscribe" hide-footer
      hide-header tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="">
        <img src="/img/freetrial.png" alt="" style="width: 30%;position: absolute;right: -7px;top: -10px;">
      </div>
      <div class="modal-header  border-0 pb-0">

        <button type="button" style="color: #e62e53" class="close" data-dismiss="modal" aria-label="Close">

          <span @click="closepopup()" aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel"></h4>
      </div>
      <b-row>
        <b-col cols="12">
          <div style="margin-top:-4px" class="">
            <p><img src="/img/Expo-01.png" style="width: 40%;margin-left: 30%; margin-bottom:10px" alt="" /></p>
            <p style="text-align: center; font-weight: 600;" class="mt-lg-3">
            <h3 style="font-weight: 600; color: #e62e53; text-align:center ">10% Discount For Limited Time!</h3>
            <p style="text-align: center; "> Send your contact details to get additional 10% discount.
            </p>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-row class="ml-lg-2">
          <b-col cols="12" class="p-4 discountbox col-lg-6" style="">
            <div style="">
              <h1 style="margin-bottom:-25px; margin-top:-3px"><b>Special</b></h1>
              <p style="font-size:110px" class="mb-0"><b><i> 10% </i></b></p>
              <h2 style="margin-top:-25px"><b>Aditional Discount!</b></h2>
            </div>
          </b-col>
          <b-col cols="12" class="px-4 col-lg-6">
            <CForm class="text-center" @submit.prevent="sendEnquirypopup">

              <CRow class="pb-3">
                <CCol cols="12" sm="12">
                  <b-form-input type="email" style="height:4rem;" placeholder="Email"
                    pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" v-model="inquiry.email" required>
                  </b-form-input>
                </CCol>
              </CRow>
              <CRow class="pt-lg-3">
                <CCol cols="12" sm="12">
                  <b-form-input class="mb-lg-3" style="height:4rem;" type="number" v-model="inquiry.mobile" id="mobile"
                    placeholder="Enter Number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="14" minlength="8" required></b-form-input>
                </CCol>
              </CRow>

              <CRow class="">
                <CCol sm="12" class="mt-3 ">
                  <button class="text-white btn btn-three poupbtnsubmit " :disabled="disabled == 1">
                    Submit
                  </button>
                  <span v-if="loading == true">
                    <LoadingBar />
                  </span>
                  <span class="text-primary">{{ messagemail }}</span>
                </CCol>
              </CRow>
            </CForm>
          </b-col>
        </b-row>

      </b-row>
    </b-modal>

    <section>
      <Footer />
    </section>
  </div>
</template>
<script>
import HeaderStats from "../components/Headers/HeaderStats";
import Footer from "../components/Footers/Footer";
import LoadingBar from "../components/LoadingBar";
import useVuelidate from "@vuelidate/core";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Multiselect from "vue-multiselect";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Countdown from 'vuejs-countdown'
import Plans from "../components/plans";

export default {
  name: "Home",

  components: {
    HeaderStats, Countdown,
    Footer,
    LoadingBar,
    VueSlickCarousel,
    Multiselect, Plans
  },
  data() {
    const now = new Date();
    const newYear = '26-12-2021';

    return {
      time: newYear - now,
      settingco: {

        "arrows": false,

        "focusOnSelect": true,
        "infinite": true,
        // "fade": true,
        "autoplay": true,

        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "touchThreshold": 5,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,

            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      max: 10,
      disabled: 0,
      loading: false,
      userlistdisplay: null,
      success: null,
      cartvalue: null,
      error: null,
      validationErrors: null,
      Countryoptions123: [],

      options: [
        { value: null, text: "Please select an option" },
        { value: "india", text: "India" },
        { value: "United State", text: "United State" },
      ],

      signupdetails: {
        name: null,
        email: null,
        mobile: null,
        check_terms: null,
        selected_radio: null,
        password: null,
        password_confirmation: null,
        location: null,
      },
      inquiry: {
        fname: null,
        lname: null,
        email: null,
        message: null,
        mobile: null,
      },
      messagemail: null,
      pland: {
        userid: null,
      },
      dataplan: {
        value: null,
        planid: 16,
      },
      popup: 0,
      localStoragePopup: 0,
      slide: 0,
      sliding: null
    };
  },

  computed: {
    ...mapGetters(["user", "errors"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  setup() {
    return { v1$: useVuelidate() };
  },

  mounted() {
    if (this.popup != 1) {
      window.setInterval(() => {
        this.onWindowLoad();
      }, 3000);
    } else {
      this.closepopup();
    }
    if (this.$route.params.params == 'verify') {
      alert(this.$route.params.params);
    } else {
      // alert(this.$route.params);
    }
  },
  watch: {
    popup: function (val) {
      if (val == 1) {
        this.closepopup();
      }
    },
  },
  methods: {
    ...mapActions([
      "getUserData",
      "sendLogoutRequest",
      "addToCart12",
      "sendInquiryE",
      "AllotedPlanFind",
      "CountryDropDynamic"
    ]),

    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    onWindowLoad() {
      if (this.popup != 1 && localStorage.getItem("popup") != 1) {
        this.$refs["my-modal"].show();
      }
    },
    closepopup() {
      this.$refs["my-modal"].hide();
      this.popup = 1;
      localStorage.setItem("popup", 1);
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    verifyResend() {
      this.success = this.error = null;
      this.getUserData()
        .then(() => {
          this.success =
            "A fresh verification link has been sent to your email address.";
        })
        .catch((error) => {
          this.error = "Error sending verification link.";
        });
    },
    validations() {
      return {
        inquiry: {
          mobile: {
            required,
            minLength: minLength(10),
          },
        },
      };
    },
    sendEnquiry() {
      this.disabled = 1;
      this.loading = true;
      this.sendInquiryE(this.inquiry).then((res) => {
        this.loading = false;
        this.messagemail = "Thank You For Inquiry";
        window.location.reload();
      });
    },
    sendEnquirypopup() {
      this.disabled = 1;
      this.loading = true;
      this.sendInquiryE(this.inquiry).then(() => {
        this.loading = false;
        this.messagemail = "Thank You For Inquiry";
        this.closepopup();
      });
      // }
    },
  },
};
</script>
<style scoped>
.poupbtnsubmit {
  border: none;
  padding: 0.5rem 9rem;
  height: 4rem
}

.discountbox {
  color: white;
  text-align: center;
  background-image: linear-gradient(rgb(8, 102, 243), rgb(10, 11, 80));
}

.bannerImg {
  min-height: 100%;
  min-width: 100%;
  background-size: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: url("/img/Group1488.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bannermr {
  margin-bottom: 14rem !important;
  margin-top: 4rem !important;
}

.bg-contact {
  background-image: url(/img/contactus_banner.png);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
  .close {

    margin: 0rem 0rem -1rem auto !important;
  }

  .poupbtnsubmit {
    padding: 0.5rem 5rem !important;
    height: 3rem
  }

  .discountbox {
    margin-left: 0.6rem;
    margin-bottom: 1rem;
  }

  .bg-contact {
    background-image: none !important;
  }

  .text-banner {
    margin-top: 3rem !important;
  }

  .bannermr {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }

  .shadow-effect {
    height: 22rem;
  }

  .p_fontfamilytestimonial {
    padding-top: 0;
    padding-bottom: 0;
  }



}

@media (max-width: 1370px) {

  .shadow-effect {
    height: 22rem;

  }

}

.backshadow {
  border-radius: 25px !important;
  transition: box-shadow 0.3s;
}

.backshadow:hover {
  box-shadow: 2px 2px 2px -18px rgb(255 255 255 / 96%),
    1px 1px 25px 0px rgb(246 246 246);
  /* cursor: pointer; */
}

.font-1 {
  font-size: 1.3em !important;
}

.h2-color {
  color: #fa2851 !important;
}

.pd-60 {
  padding: 60px !important;
}

.bg-img {
  background-image: url(/img/about-us-background.png);
  background-size: auto !important;
}

.mlabout {
  margin-left: 5rem;
}

.white-color {
  color: white;
}

.custWith {
  width: 1%;
}

.custWith123 {
  width: 5%;
}

.bgimage {
  background-image: url("/img/Group1487.svg");
}

.bgimage2 {
  background-image: url("/img/MaskGroup5.svg");
  width: 100%;
}

.font-color {
  color: #1e38a7;
}

.widthfull {
  width: 100%;
}

.blueclass {
  color: #1e38a7;
}

.background_effect:nth-child(odd) {
  background: #1e38a7 !important;
  color: #f8fbfd;
}

.background_effect:nth-child(odd) span:hover a {
  color: white;
}

.background_effect:nth-child(even) span:hover a {
  color: #fa2b56;
}

.about {
  transition: box-shadow 0.3s;
}

.about:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.bg-pink {
  background-color: #fa2b56;
}

.pink {
  color: #fa2b56;
}

.raise:hover,
.raise:focus {
  box-shadow: 1px 1px 3px 3px rgb(235, 230, 230);
  transform: translateY(-0.25em);
}

.cont_ani {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  padding: 30px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.aboutus_ani {
  animation: 1s ease-out 0s 1 slideInFroright;
  /* background: #333; */
  padding: 30px;
}

@keyframes slideInFroright {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.btn-three34 span a {
  text-decoration: none;
}

.btn-three34 span {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  border-radius: 25px;
}

.btn-three34:hover span {
  background: none;
  border: 1px solid #fa2b56;
  color: #fa2851 !important;
}

.btn-three35 {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  box-shadow: 2px 2px;
  cursor: pointer;
}

.btn-three35:hover {
  background: none;
  /* border: 1px solid #FA2B56; */
  color: #fa2851 !important;
}

.btn-three35 a:hover {
  text-decoration: none;
  color: #fa2856;
}

.btn-three {
  color: #fff;
  font-weight: 700;

  transition: all 0.5s;
  position: relative;
  padding: 0.5rem;
  background-color: #fa2b56;
}

.btn-three11 {
  color: #000 !important;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem;
  background-color: #fff;
  text-decoration: none;
}

.btn-three::after {
  /* content: ''; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}

.btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.sub-plans {
  padding-left: 4rem;
  padding-right: 4rem;
}

.text-banner {
  margin-top: 10rem;
}

ul {
  list-style-type: none;
  text-align: left;
}

.tooltip {
  transform: none;
  /* margin: 50px;     */
}

.wrapper {
  /* position:relative; */
  width: 34rem;
}

.tooltip:hover>.tooltip-text,
.tooltip:hover>.wrapper {
  /* pointer-events: auto; */
  opacity: 1;
}

.tooltip>.tooltip-text,
.tooltip>.wrapper {
  display: block;
  z-index: 6000;
  overflow: visible;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #fff;
  background: #000;
  pointer-events: none;
  opacity: 0;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* Arrow */
.tooltip>.tooltip-text:before,
.tooltip>.wrapper:before {
  display: inline;
  /* top: -5px; */
  content: "";
  /* position: absolute; */
  border: solid;
  border-color: rgba(0, 0, 0, 1) transparent;
  border-width: 0 0.5em 0.5em 0.5em;
  z-index: 6000;
  left: 20px;
}

.close {

  margin: -2rem 0rem -1rem auto;
}

/* Invisible area so you can hover over tooltip */
.tooltip>.tooltip-text:after,
.tooltip>.wrapper:after {
  /* top: -20px; */
  content: " ";
  display: block;
  height: 20px;
  /* position: absolute; */
  width: 30rem;
  left: 20px;
}

.wrapper>.tooltip-text {
  overflow-y: auto;
  max-height: 10rem;
  max-width: 25rem !important;
  display: block;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.curser-pointer {
  cursor: pointer;
}

.title-popup1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;

  color: #2748c6;

  text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.shadow-effect {
  height: 18rem;
  background: #fff;
  padding: 20px;
  border-radius: 26px;
  text-align: center;
  border: 1px solid #ECECEC;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02); */
}

#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}

.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #e62e53;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}

#customers-testimonials .item {
  text-align: center;
  /* padding: 50px; */
  margin-bottom: 2rem;
  opacity: .2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#customers-testimonials .item {
  opacity: 1;
  -webkit-transform: scale3d(1.0, 1.0, 1);
  transform: scale3d(1.0, 1.0, 1);
}

.slick-slide img[data-v-e4caeaf8] {
  display: inline !important;
}

.p_fontfamilytestimonial {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  padding-top: 1.5rem;
}

.extra {
  padding-bottom: 2px;
}

.backblue {
  background: #1e38a7;

  padding: 25px;
  /* border-radius: 25px !important; */
  transition: box-shadow 0.3s;
}

.backgroundimg {
  background-color: red !important;
  background-image: url('/img/Expo-01.png') !important;
  /* background-image: url('/img/freetrial.png');
  background-size: contain;
    background-repeat: no-repeat;
    
    background-position: right; */
}

.bluetitle {
  color: #2748c6;
}

.marginb {
  margin-bottom: 1.8rem;
}

/* @media (min-width: 1000px){ */

/* } */
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
